// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require.context('../images', true);

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('jquery'); // TODO Remove?
require('popper.js');

import './bootstrap-custom.js';
import '@fortawesome/fontawesome-free/js/all';